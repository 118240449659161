import {pageBuilderService} from "@/services/pageBuilder.service";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset} from '@/utils/utils-functions'


export const pageBuilder = {
    namespaced: true,
    state: {
      screen: [],
      node: [],
      field: [],
      widget: [],
      group: [],
      preset: [],
      process: [],
      function: [],
      collection: [],
      wrapper: [],
      currentList: [],
      TYPE_REFS: {},
      currentNode: null,
      variables: [],
      searchedVariable: [],
      loadedAllData: false,
      activeElementSettings: null,
    },
    getters: {
      screen: state => state.screen,
      node: state => state.nodes,
      field: state => state.field,
      widget: state => state.widget,
      group: state => state.group,
      preset: state => state.preset,
      process: state => state.process,
      function: state => state.function,
      collection: state => state.collection,
      wrapper: state => state.wrapper,
      types: state => state.TYPE_REFS,
      currentList: state => state.currentList,
      activeElementSettings: state => state.activeElementSettings,
        currentNode: state => state.currentNode,
        variables: state => state.variables,
        searchedVariable: state => state.searchedVariable,
        loadedAllData: state => state.loadedAllData
    },
    mutations: {
      getElementsByType(state, response) {
          state[state.TYPE_REFS.find(type => type.id === response.id).prefixName] = response.data;
          state.currentList = response.data;
      },
      getTypesDictionary(state, value) {
          state.TYPE_REFS = value;
      },
        getNode(state, node) {
            state.currentNode = node
        },

        setVariables(state, value) {
            state.variables = [...state.variables, ...value.fieldNames]
        },
        setSearchedVariable(state, value){
            state.searchedVariable = state.searchedVariable?.length
                ? [...state.searchedVariable, ...value]
                : value
        },
        deleteVariable(state, {id, searchData}){
            state.variables = state.variables.filter(field => field.id !== id)
            if(Object.values(searchData).some(item => item)) state.searchedVariable = state.searchedVariable.filter(field => field.id !== id)
        },
        addVariable(state, {dataVariable, searchData}){
            state.variables.push(dataVariable)
            if(Object.values(searchData).some(item => item)) {
                state.searchedVariable?.length
                    ? state.searchedVariable.push(dataVariable)
                    : state.searchedVariable = [dataVariable]
            }
        },
        cleanSearchField(state){
            state.searchedVariable = null
        },
        cleanVariables(state){
            state.variables = []
        },
        setLoaded(state, loaded){
            state.loadedAllData = loaded
        },
      setActiveElementSettings(state, id) {
        state.activeElementSettings = id;
      }
    },
    actions: {
      async getTypesDictionary({commit}) {
        const response = await pageBuilderService.getTypesDictionary();
        commit('getTypesDictionary', response.response);
      },
      async getElementsByType({commit}, nodeType) {
          const response = await pageBuilderService.getElementsByType(nodeType);
          commit('getElementsByType', {id: nodeType, data: response.response});
      },
      async createNode({commit}, payload) {
          return await pageBuilderService.createNode(payload);
      },
      setActiveElementSettings({commit}, payload) {
        commit("setActiveElementSettings", payload);
      },
      async changeNode({commit, state}, nodeInfo) {
        console.log('nodeInfo - ', nodeInfo);
        if (typeof nodeInfo.nodePart.nodeType !== 'number') {
          nodeInfo.nodePart = {
            ...nodeInfo.nodePart,
            nodeType: state.TYPE_REFS.find(type => type.prefixName === nodeInfo.nodePart.nodeType)?.id
          };
        }
          if (nodeInfo.nodePart?.nodeFields || nodeInfo.nodePart?.presets || nodeInfo.nodePart?.screens) {
              nodeInfo.nodePart = {...nodeInfo.nodePart, childrenNodes: nodeInfo.nodePart?.nodeFields || nodeInfo.nodePart?.presets || nodeInfo.nodePart?.screens};
              delete nodeInfo.nodePart.nodeFields;
              delete nodeInfo.nodePart.presets;
              delete nodeInfo.nodePart.screens;
          }
          return await pageBuilderService.changeNode(nodeInfo.partId, nodeInfo.nodePart);
      },
      async getNodeProperties({commit}, payload) {
        return await pageBuilderService.getNodeProperties(payload);
      },
        async getNode({commit}, db_id) {
            const response = await pageBuilderService.getNode(db_id);
            commit('getNode', response.response);
        },
        async getVariables({commit, dispatch, getters}, searchData){
            const response = await pageBuilderService.getVariables({offset: searchData?.offset || 0})

            if(getters.variables.length >= response.response.total - 1) commit('cleanVariables')
            commit('setVariables', response.response);

            const loaded = await requestWithOffset(response.response, searchData, 'getVariables', dispatch)
            if(loaded) commit('setLoaded', loaded)
        },

        async getDataFiltered({commit, dispatch}, searchData){
            const isType = true // need to hide type in request
            const keys = Object.keys(searchData)
            const availableKey = keys.find(key => searchData[key] !== '' && key !== 'offset')

            const data = {
                isType,
                type: availableKey,
                search: availableKey ? searchData[availableKey] : '',
                offset: searchData?.offset || 0
            }
            const response = await pageBuilderService.getVariables(data)

            if(!response?.response?.fieldNames?.length ) {
                dispatch('alert/error', i18n.t('variables.alert.errorFindVariable'), { root: true })
                return commit('setLoaded', true)
            }
            commit('setSearchedVariable', response.response.fieldNames)

            const loaded = await requestWithOffset(response.response, searchData, 'getDataFiltered', dispatch)
            if(loaded) commit('setLoaded', loaded)
        },

        async getDataById({commit, dispatch}, {id, show}) {
            const response = await pageBuilderService.getVariableById(id)

            commit('setLoaded', true)
            if(!response?.response?.id) return dispatch('alert/error', i18n.t('variables.alert.errorFindVariable'), { root: true })
            if(!show) {
                commit('setSearchedVariable', [response.response])
                commit('setLoaded', true)
            }

            return response.response
        },

        loadData({commit}){
            commit('setLoaded', false)
        },

        cleanSearchedData({commit}){
            commit('cleanSearchField')
        },

        async createVariable({commit, dispatch}, {newData, searchData}){
            const response = await pageBuilderService.createVariable(newData)

            if(!response.response || response.status === 0) {
                dispatch('alert/error', i18n.t('variables.alert.createError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('variables.alert.createSuccess'), { root: true })

                const createdVar = await pageBuilderService.getVariableById(response.response.id)
                commit('addVariable', {dataVariable: createdVar.response, searchData})

                return response.response.id
            }
        },

        async updateVariable({commit, dispatch}, {varData, searchData}){
            const response = await pageBuilderService.updateVariable(varData)

            if(!response?.response || response?.status === 0){
                dispatch('alert/error', i18n.t('variables.alert.updateError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('variables.alert.updateSuccess'), { root: true })

                commit('deleteVariable', {id: varData.id, searchData})
                commit('addVariable', {dataVariable: varData, searchData})
            }
        },
        async deleteVariable({commit, dispatch}, {id, searchData}){
            const response = await pageBuilderService.deleteVariable(id)

            if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
                dispatch('alert/error', i18n.t('variables.alert.deleteError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('variables.alert.deleteSuccess'), { root: true })

                searchData?.id
                    ? dispatch('cleanSearchedData')
                    : commit('deleteVariable', {id, searchData})
            }
        }
    }
}