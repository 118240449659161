import { nodeService } from "@/services/node.service";
import { pageBuilderService } from "@/services/pageBuilder.service"
import Vue from "vue";
import {refactorFieldToView, requestWithOffset} from "@/utils/utils-functions";

import * as canvasIcons from "@/assets/icons/canvas";
import {DEFAULT_CANVAS_THEME} from "@/constants/canvas";
const staticN8nPath = process.env.VUE_APP_GRAPH_STATIC_URL

function changeFieldProperties(field, props) {
  if (props) {
    Object.keys(props).forEach(key => {
      if (field.type === key || (key === 'block' && field.type === 'block.html')) {
        field.properties = {...props[key], ...field.properties}
      }
    })
  }
  return field;
}

const propsRef = {
  background: 'transparent', // +
  title: '', // + -
  align: 'center', // +
  bold: false, // +
  verticalAlign: 'center', // ?
  fontSize: 14, // +
  fontMainText: 14,
  text: '', // +
  visibility: true, // +
  color: '#000', // +
  underline: false, // +
  font: null, // +
  placeholder: '', // +
  mask: '', // +
  length: '', // +
  label: '', // +
  url: '', // +
}

export const node = {
  namespaced: true,
  state: {
    settings: null,
    defaultSettings: null,
    currentScreen: null,
    nodeId: null,
    nodePreset: null,
    nodeType: null,
    currentField: null,
    currentScreenId: null,
    fieldProps: null,
    outerConnections: [],
    nodeEditLoading: false,
    presets: null,
    availableNodes: {},
    conditions: [],
    conditionsOptions: {},
    connections: null,
    currentNode: null,
    currentPreset: null,
    availableScreens: [],
    heightOfPopup: 0,
    heightScreenEditComponent: 0,
    findMethod: null,
    presetsOnSelectedNode: null,
    screensOnSelectedNode: null,
    selectNodeOnCanvas: null,
  },

  getters: {
    settings: state => state.settings,
    defaultSettings: state => state.defaultSettings,
    generalNodeSettings: state => state.settings.properties,
    currentScreen: state => state.currentScreen,
    nodeId: state => state.nodeId,
    nodePreset: state => state.nodePreset,
    presets: state => state.presets,
    nodeType: state => state.nodeType,
    currentField: state => state.currentField,
    currentScreenId: state => state.currentScreenId,
    outerConnections: state => state.outerConnections,
    nodeEditLoading: state => state.nodeEditLoading,
    /* eslint-disable */
    availableNodes: state => state.availableNodes,
    conditions: state => state.conditions,
    conditionsOptions: state => state.conditionsOptions,
    connections: state => state.connections,
    currentNode: state => state.currentNode,
    currentPreset: state => state.currentPreset,
    availableScreens: state => state.availableScreens,
    heightOfPopup: state => state.heightOfPopup,
    heightScreenEditComponent: state => state.heightScreenEditComponent,
    screens: state => state.settings.screens,
    findMethod: state => state.findMethod,
    presetsOnSelectedNode: state => state.presetsOnSelectedNode,
    screensOnSelectedNode: state => state.screensOnSelectedNode,
    selectNodeOnCanvas: state => state.selectNodeOnCanvas
  },

  mutations: {
    setSettings(state, settingsInfo) {
      console.log("settingsInfo - ", settingsInfo)
      state.settings = settingsInfo.settings
      state.defaultSettings = JSON.parse(JSON.stringify(settingsInfo.settings))
      // Временная мера -> фейк главная страница, пока не готова реальная
      if (state.settings && state.settings.id !== 999 && state.settings.title) {
        state.settings.screens.forEach(screen => {
          screen.nodeFields.map(field => {
            return changeFieldProperties(field, state.fieldProps)
          })
        })
      }
    },
    setAvailableNodes(state, { palette, canvasTheme = DEFAULT_CANVAS_THEME }) {
      Object.keys(palette).forEach((key) => {
        const imageName = !!palette[key]?.icon?.match(/\//g)?.length || !palette[key].icon
          ? 'circle'
          : palette[key].icon.split(".")[0].toLowerCase()
        // See @/assets/icons/canvas/index.js
        const icon = canvasIcons[canvasTheme][imageName];
        palette[key].icon = icon;
      });

      state.availableNodes = {...state.availableNodes, ...palette};
    },
    setNodeEditLoading(state, value) {
      state.nodeEditLoading = value
    },
    setNullSettings(state, settings) {
      state.settings = settings
      state.defaultSettings = settings
      state.currentScreen = settings
    },
    setNodeId(state, id) {
      state.nodeId = id
    },
    setPresets(state, presets) {
      state.presets = presets;
    },
    setNodePreset(state, preset) {
      state.nodePreset = preset
    },
    setNodeType(state, type) {
      state.nodeType = type
    },
    setScreenDisable(state, screenProps) {
      state.settings.screens.map(setting => {
        if (setting.id === screenProps.screenId) {
          Vue.set(setting.properties, "visibility", screenProps.visibility)
        }
        return setting
      })
    },
    setEditScreen(state, setting) {
      state.currentScreen = setting
      state.settings?.screens.forEach(screen => {
        // eslint-disable-next-line no-prototype-builtins
        if (
            screen.id === state.currentScreen.id &&
            screen.properties?.visibility
        ) {
          state.currentScreen.properties.visibility =
              screen.properties.visibility
        }
      })
      if (state.currentScreen) {
        state.currentScreen.nodeFields.map(field => {
          return changeFieldProperties(field, state.fieldProps)
        })
      }
    },
    setScreenSettings(state, settings) {
      state.settings.screens = state.settings.screens.map(setting => {
        if (setting.id === settings.id) {
          setting = JSON.parse(JSON.stringify(settings))
        }
        return setting
      })
    },
    setCurrentField(state, fieldName) {
      state.currentField = fieldName
    },
    setCurrentScreenId(state, id) {
      state.currentScreenId = id
    },
    setFieldProps(state, props) {
      state.fieldProps = props.map(prop => {
        Object.keys(propsRef).forEach(propRef => {
          if(Object.keys(prop.properties).hasOwnProperty(String(propRef))) return
          else prop.properties[propRef] = propsRef[propRef]
        })
        return prop
      })
    },

    setOuterConnections(state, connections) {
      state.outerConnections = connections
    },

    getConditions(state, data) {
      state.conditions = [...state.conditions, ...data.conditions]

      if(data.allConditions){
        const allConditions = state.conditions.sort((a, b) => a.ruleName > b.ruleName ? 1 : -1);

        const needPreset = allConditions.find(item => item.ruleName === "Переход")
        state.conditions = allConditions.filter(item => item.ruleName !== "Переход")
        state.conditions.unshift(needPreset)
      }
    },

    setConnections(state, data){
      state.connections = data
    },

    setCurrentNode(state, node){
      state.currentNode = node
    },

    setCurrentPreset(state, id){
      state.currentPreset = state.presets?.find(preset => preset.id === id)    
    },

    setAvailableScreens(state, {availableScreens}){
      if(!(
          availableScreens[0]?.nodeId && 
          state.availableScreens.find(screen => screen.nodeId === availableScreens[0]?.nodeId)
        )) state.availableScreens.push(...availableScreens)
    },

    setHeightOfPopup(state, height){
      state.heightOfPopup = height
    },
    setHeightScreenEditComponent(state, height){
      state.heightScreenEditComponent = height
    },
    setFindMethod(state, type){
      state.findMethod = type
    },

    getPresetsOnSelectedNode(state, presets){
      state.presetsOnSelectedNode = presets
    },

    getScreensOnSelectedNode(state, screens){
      state.screensOnSelectedNode = screens
    },
    setSelectNodeOnCanvas(state, node){
      state.selectNodeOnCanvas = node
    }
  },

  actions: {
    async getNodeId({commit}, payload) {
      const nodeInfo = await nodeService.getNodeId(payload)
      nodeInfo.response.db_id = nodeInfo.response.id;
      nodeInfo.response.parent_id = payload.node_id;
      delete nodeInfo.response.id;
      return nodeInfo.response;
    },
    // DELETE /api/v1/bpm/nodes/{node_id}
    async deleteNode({commit}, id) {
      await nodeService.deleteNode(id);
    },
    async getAvailableNodes({ commit, rootState, dispatch }, payload) {
      await nodeService.getAvailableNodes(payload?.offset || 0)
      .then(async palette => {
        const resPalette = palette.response.pallet.reduce((acc, value) => {
          // eslint-disable-next-line no-prototype-builtins
          return {...acc, [Object.keys(value)]: value[Object.keys(value)]}
        }, {})
          commit("setAvailableNodes", {
            palette: resPalette,
            canvasTheme: payload?.canvasTheme || DEFAULT_CANVAS_THEME,
          });

          await requestWithOffset(palette.response, payload, 'getAvailableNodes', dispatch)
        }).catch((e) => {
          console.log('e - ', e);
        })
    },
    async setSettings({commit, state}, {id, preset, type, connections = null, lang='en'}) {
      console.log('id - ', id);
      console.log('preset - ', preset);
      console.log('type - ', type);
      console.log('connections - ', connections);
      let nodeId = null;
      let nodePreset = null;
      let nodeType = null;
      if (id) {
        nodeId = id;
        nodePreset = preset;
        nodeType = type;
      } else {
        nodeId = state.nodeId;
        nodePreset = state.nodePreset;
        nodeType = state.nodeType;
      }
      // Временная мера -> фейк главная страница, пока не готова реальная
      if (id != 999) {
        await nodeService
          .getNode('node', nodeId, nodePreset, connections, lang)
          .then(settings => {
            console.log("🚀 ~ here settings:", settings)
            commit("setSettings", {settings: settings.response, id: nodeId, preset: nodePreset});
            commit("setNodeId", nodeId);
            commit("setPresets", settings.response?.presets);
            commit("setNodePreset", nodePreset);
            commit("setNodeType", nodeType);
          })
          .catch(() => {
            commit("setSettings", {
              settings: {title: null},
              id,
              preset
            });
            commit("setNodeId", id);
            commit("setNodePreset", preset);
            commit("setNodeType", nodeType);
          })
      } else {
        commit("setSettings", {
          settings: {title: "Главная", id: 999},
          id,
          preset
        });
        commit("setNodeId", id);
        commit("setNodePreset", preset);
        commit("setNodeType", nodeType);
      }
    },
    setNullSettings({ commit }) {
      commit("setNullSettings", null)
    },
    setScreenDisable({ commit }, screenProps) {
      commit("setScreenDisable", screenProps)
    },
    async setEditScreen({ commit }, {id, showWidget}) {
      const response = await nodeService.getNode('screen' ,id, null, null, sessionStorage.getItem('lastSelectedLang') || 'en');
      if (response.status === 1) {
        response.response.nodeFields.map((field) => {
          // refactor text to wyswig
          if (typeof field.properties !== 'undefined' && field.properties.hasOwnProperty('text')) {
            // set initial main style class to local storage each field
            const tempSpan = document.createElement('span');
            tempSpan.innerHTML = field.properties.text;
            if(String(tempSpan?.firstChild).includes('<span') && tempSpan?.firstChild?.getAttribute("class")) {
              localStorage.setItem(`field-style-${field.name}`, tempSpan.firstChild.getAttribute("class"));
            }
            field.properties.text = refactorFieldToView(field.properties.text);
          }

          // refactor data to wyswig
          if (typeof field.properties !== 'undefined' && field.properties.hasOwnProperty('data') && !!field.properties.data) {
            console.log('field - ', field);
            field.properties.data = JSON.stringify(JSON.parse(field.properties.data).map(elem => {
              return refactorFieldToView(elem);
            }))
            console.log("field props data - ", field.properties.data);
          }

          // refactor input props to wyswig
          if (field.nodeType.includes('input')) {
            field.properties.placeholder = refactorFieldToView(field.properties.placeholder);
            if (typeof field.properties !== 'undefined' && field.properties.hasOwnProperty('label')) {
              field.properties.label = refactorFieldToView(field.properties.label);
            }
            if (typeof field.properties !== 'undefined' && field.properties.hasOwnProperty('mask')) {
              field.properties.mask = refactorFieldToView(field.properties.mask);
            }
          }
        })
        commit("setEditScreen", {...response.response, showWidget})
      } else {
        commit("setEditScreen", null)
      }
    },
    async saveScreenSettings({ commit }, screenSettings) {
      const lang = sessionStorage.getItem('lastSelectedLang') || 'en';
      await nodeService
        .saveScreenSettings(screenSettings.id, {lang, ...screenSettings})
        .then(() => commit("setScreenSettings", screenSettings))
    },
    async saveNode({ state }, settings) {
      await nodeService.saveNode(state.nodeId, state.nodePreset, settings)
    },
    setCurrentField({ state, commit }, fieldName) {
      let name = fieldName
      if (state.currentField === name) {
        name = null
      }
      commit("setCurrentField", name)
    },
    setCurrentScreenId({ commit }, id) {
      commit("setCurrentScreenId", id)
    },
    async setFieldProps({ commit }) {
      const props = await pageBuilderService.getFieldProps();
      if (props.status === 1) {
        commit("setFieldProps", props.response);
      } else {
        commit("setFieldProps", null);
      }
    },
    setOuterConnections({ commit }, connections) {
      commit("setOuterConnections", connections)
    },
    newCondition({ commit }, condition) {
      commit("newCondition", condition)
    },
    changeCondition({ commit }, condition) {
      commit("changeCondition", condition)
    },
    newTransition({ commit }, transition) {
      commit("newTransition", transition)
    },
    deletePreset({ commit }, transition) {
      commit("deletePreset", transition)
    },
    addPreset({ commit }, transition) {
      commit("addPreset", transition)
    },
    async getConditions({ commit, dispatch }, data) {
        await nodeService
          .getConditions(data?.offset || 0)
          .then(options => {
            const res = options.response
            // Fixme: fix naming
            const allConditions = (res.all + res.offset === res.total)
            
            commit("getConditions", {conditions:res.conditions, allConditions})


            requestWithOffset(options.response, data, 'getConditions', dispatch)
          })
          .catch(() => {
            commit("getConditions", {conditions: []})
          })
    },
    
    setConnections({commit}, data){
      commit('setConnections', data)
    },

    setCurrentNode({commit}, node) { 
      commit('setCurrentNode', node)
    },

    setCurrentPresetId({commit}, id) {
      commit('setCurrentPreset', id)
    },

    async setAvailableScreens({commit, getters}, connections) {

      // add current node
      const connectWithCurrentNode = connections
      connectWithCurrentNode.push(getters.currentNode.db_id)
      
      for(let i = 0; i < connectWithCurrentNode.length; i++){
        await nodeService.getAvailableScreens(connectWithCurrentNode[i])
        .then(res => {
          commit('setAvailableScreens', {availableScreens: res.response, node_id: connectWithCurrentNode[i]})
        })
        .catch(err => console.log('error', err))
      }
    },

    heightOfPopup({commit}, height){
      commit('setHeightOfPopup', height)
    },

    heightScreenEditComponent({commit}, height){
      commit('setHeightScreenEditComponent', height)
    },
    setFindMethod({commit}, type){
      commit('setFindMethod', type)
    },

    async getPresetsOnSelectedNode({commit}, id){
      const presets = await nodeService.getAvailableScreens(id)

      if (presets?.status === 1) {
        commit('getPresetsOnSelectedNode', presets.response);
      } else {
        commit('getPresetsOnSelectedNode', null);
      }
    },

    async getScreensOnSelectedNode({commit}, id){
      const screens = await nodeService.getAvailableScreens(id)

      if (screens?.status === 1) {
        commit('getScreensOnSelectedNode', screens.response);
      } else {
        commit('getScreensOnSelectedNode', null);
      }
    },

    setSelectNodeOnCanvas({commit}, node){
      commit('setSelectNodeOnCanvas', node)
    }
  },
}
