export default {
  data () {
    return {
      sidebarItems: [
        // {
        //   name: 'main',
        //   link: '/',
        //   title: 'Главная',
        //   icon: 'mdi-home-outline',
        //   color: '#596CFF'
        // },
        {
          name: 'business-process',
          link: '/business-process',
          title: 'processes',
          icon: 'mdi-cellphone-cog',
          color: '#FD7E14'
        },
        // {
        //   name: 'widgets',
        //   link: '/widgets',
        //   title: 'widgets',
        //   icon: 'mdi-widgets-outline',
        //   color: '#2dce89'
        // },
        {
          name: 'app-translate',
          link: '/app-translate',
          title: 'multiLang',
          icon: 'mdi-translate',
          color: '#f5365c'
        },
        {
          name: 'main-props',
          link: '/main-props',
          title: 'settings',
          icon: 'mdi-cog-outline'
        },
        // {
        //   name: 'node-constructor',
        //   link: '/node-constructor',
        //   title: "pageBuilder",
        //   icon: "mdi-account-hard-hat"
        // },
        {
          name: 'variables-and-rules',
          link: 'variables-and-rules',
          title: 'variables-and-rules',
          icon: 'mdi-hexagon-multiple-outline',
          subItems: [
            {
              name: 'variables',
              link: '/variables',
              title: 'variables',
              icon: 'mdi-variable'
            },
            {
              name: 'rules',
              link: '/rules',
              title: 'rules',
              icon: 'mdi-alpha-x-circle-outline'
            },
          ]
        },
        {
          name: 'integration',
          link: 'integration',
          title: 'integration',
          icon: 'mdi-connection',
          subItems: [
            {
              name: 'external-sources',
              link: '/external-sources',
              title: 'external-sources',
              icon: 'mdi-crowd'
            },
            {
              name: 'endpoints',
              link: '/endpoints',
              title: 'endpoints',
              icon: 'mdi-crowd'
            }
          ]
        },
        {
          name: 'references',
          link: 'references',
          title: 'references',
          icon: 'mdi-library-outline',
          subItems: [
            {
              name: 'references-universal',
              link: '/references-universal',
              title: 'references-universal',
              icon: 'mdi-library'
            }
          ]
        },
        {
          name: 'projects-and-processes',
          link: 'projects-and-processes',
          title: 'projects-and-processes',
          icon: 'mdi-list-box-outline',
          subItems: [
            {
              name: 'projects-list',
              link: '/projects-list',
              title: 'projects-list',
              icon: 'mdi-format-list-bulleted'
            }
          ]
        },
        {
          name: 'properties',
          link: 'properties',
          title: 'properties',
          icon: 'mdi-arrange-send-backward',
          subItems: [
            {
              name: 'properties-of-node',
              link: '/properties',
              title: 'properties-of-node',
              icon: 'mdi-inbox'
            }
          ]
        },
        {
          name: 'actions',
          link: 'actions',
          title: 'actions',
          icon: 'mdi-chevron-double-right',
          subItems: [
            {
              name: 'actions-list',
              link: '/actions',
              title: 'actions-list',
              icon: 'mdi-chevron-double-right'
            }
          ]
        },

      ],
      drawer: null,
      // miniSidebar: null
    }
  }
}
