import {propertiesService} from "@/services/properties.service";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset} from '@/utils/utils-functions'

export const properties = {
    namespaced: true,
    state: {
        propertiesData: [],
        searchedPropertiesData: [],
        parentsData: [],
        propertiesTypes: [],
        loadedAllData: false,
    },
    getters: {
        propertiesData: state => state.propertiesData,
        searchedPropertiesData: state => state.searchedPropertiesData,
        parentsData: state => state.parentsData,
        propertiesTypes: state => state.propertiesTypes,
        loadedAllData: state => state.loadedAllData,
    },
    mutations: {
        setProperties(state, value) {
            state.propertiesData = [...state.propertiesData, ...value.properties]
        },
        setSearchedProperties(state, value){
            state.searchedPropertiesData = state.searchedPropertiesData?.length
                ? [...state.searchedPropertiesData, ...value]
                : value
        },
        deleteProperty(state, {id, searchData}){
            state.propertiesData = state.propertiesData.filter(field => field.id !== id)
            if(Object.values(searchData).some(item => item)) state.searchedPropertiesData = state.searchedPropertiesData.filter(field => field.id !== id)
        },
        addProperty(state, {dataProperty, searchData}){
            state.propertiesData.push(dataProperty)
            if(Object.values(searchData).some(item => item)) {
                state.searchedPropertiesData?.length
                    ? state.searchedPropertiesData.push(dataProperty)
                    : state.searchedPropertiesData = [dataProperty]
            }
        },
        cleanSearchedData(state){
            state.searchedPropertiesData = null
        },
        cleanData(state){
            state.propertiesData = []
        },
        setParentData(state, parentsData){
            state.parentsData = parentsData
        },
        setPropertiesTypes(state, types){
            state.propertiesTypes = [...state.propertiesTypes, ...types]
        },
        setLoaded(state, loaded){
            state.loadedAllData = loaded
        }
    },
    actions: {
        async getData({commit, getters, dispatch}, payload) {
            const response = await propertiesService.getData({offset: payload?.offset || 0})

            if(getters.propertiesData.length >= response.response.total) commit('cleanData')
            commit('setProperties', response.response)

            const loaded = await requestWithOffset(response.response, payload, 'getData', dispatch)
            if(loaded) {
                // move to load parentsData in 'getParentsData'

                const parentIds = getters.propertiesData
                    .filter(property => property.parentId)
                    .map(property => property.parentId)

                dispatch('getParentsData', parentIds)
            }
        },

        async getDataFiltered({commit, dispatch}, searchData){
            const isType = false // need to hide type in request
            const keys = Object.keys(searchData)
            const availableKey = keys.find(key => searchData[key] !== '' && key !== 'offset')

            const data = {
                isType,
                type: availableKey,
                search: availableKey ? searchData[availableKey] : '',
                offset: searchData?.offset || 0
            }
            const response = await propertiesService.getData(data)

            if(!response?.response?.properties?.length) {
                dispatch('alert/error', i18n.t('property.alert.searchError'), { root: true })
                return commit('setLoaded', true)
            }
            commit('setSearchedProperties', response.response.properties)

            const loaded = await requestWithOffset(response.response, searchData, 'getDataFiltered', dispatch)
            if(loaded) commit('setLoaded', loaded)
        },

        async getDataById({commit, dispatch}, {id, show}) {
            const response = await propertiesService.getDataById(id)

            commit('setLoaded', true)
            if(!response?.response?.id) return dispatch('alert/error', i18n.t('property.alert.searchError'), { root: true })
            if(!show) {
                commit('setSearchedProperties', [response.response])
                commit('setLoaded', true)
            }

            return response.response
        },

        async getParentsData({commit}, parentIds){
            let parentsData = []
            
            for(let parentId of parentIds){
                const response = await propertiesService.getDataById(parentId)
                parentsData.push(response.response)
            }
            commit('setParentData', parentsData)
            commit('setLoaded', true)
        },

        loadData({commit}){
            commit('setLoaded', false)
        },

        cleanSearchedData({commit}){
            commit('cleanSearchedData')
        },

        async createProperty({commit, dispatch}, {newData, searchData}){
            const response = await propertiesService.createProperty(newData)

            if(!response?.response || response?.status === 0) {
                dispatch('alert/error', i18n.t('property.alert.createError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('property.alert.createSuccess'), { root: true })

                const createProperty = await propertiesService.getDataById(response.response.id)
                commit('addProperty', {dataProperty: createProperty.response, searchData})

                return response.response.id
            }
        },

        async updateProperty({commit, dispatch}, {data, searchData}){
            const response = await propertiesService.updateProperty(data)

            if(!response?.response || response.status === 0){
                dispatch('alert/error', i18n.t('property.alert.updateError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('property.alert.updateSuccess'), { root: true })

                commit('deleteProperty', {id: data.id, searchData})
                commit('addProperty', {dataProperty: data, searchData})
            }
        },
        async deleteProperty({commit, dispatch}, {id, searchData}){
            const response = await propertiesService.deleteProperty(id)

            if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
                dispatch('alert/error', i18n.t('property.alert.deleteError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('property.alert.deleteSuccess'), { root: true })

                searchData?.id
                    ? dispatch('cleanSearchedData')
                    : commit('deleteProperty', {id, searchData})

            }
        },

        async getPropertiesTypes({commit, dispatch}, payload){
            const response = await propertiesService.getPropertiesTypes({offset: payload?.offset || 0})

            commit('setPropertiesTypes', response.response.propertiesTypes)
            await requestWithOffset(response.response, payload, 'getPropertiesTypes', dispatch)
        },
    }
}