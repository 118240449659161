<template>
  <div class="btn-toggle" :class="{'disabled': disabled}" :style="inlineStyle">
    <button
        v-for="(param,i) in params"
        :key="i"
        @click="switchParam(param, i)"
        class="btn-toggle__type"
        :class="{'active': param === currentParam}"
        :disabled="disabled"
        :style="inlineStyleBtn"
    >{{ param }}</button>
  </div>
</template>

<script>
export default {
  name: "SwitcherComp",
  data(){
    return {
      currentParam: this.selectedParam ?? this.params[0],
    }
  },
  props: {
    params: {
      type: Array,
      default: () => ['param1', 'param2']
    },
    selectedParam: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inlineStyle: {
      type: Object,
      default: null
    },
    inlineStyleBtn: {
      type: Object,
      default: null
    },
    // const inlineStyle = { [key]: value }
  },
  methods: {
    /** toggle button
     * @param {String} name button
     */
    switchParam(name, index){
      this.currentParam = name
      this.$emit('switchParam', name, index)
    }
  }
}
</script>

<style scoped lang="scss">
.btn-toggle{
  border-radius: 12px;
  background: #F6F9FC;
  padding: 6px;
  gap: 4px;
  display: flex;

  &.disabled{
    background: #eaeaea;
  }

  &__type {
    padding: 4px;
    width: 50%;
    border-radius: 8px;
    font-size: 16px;
    color: #172B4D;

    &.active{
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
      background: #FFF;
    }

    &.disabled{
      background: #eaeaea;
    }
  }
}
</style>
